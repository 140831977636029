
import Person from "~/graphql/Person/Person.gql";
import CompletePortfolio from "~/graphql/Person/CompletePortfolio.gql";

import {
  PROPERTY_TYPE_MAIN_PROPERTY,
  PROPERTY_TYPE_CONDO,
  PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT,
  PROPERTY_TYPE_UNKNOWN,
  getPropertyType,
} from "~/helpers/property-helpers.js";

export default {
  inheritAttrs: false,

  apollo: {
    person: {
      query: Person,

      update(data) {
        return data.person;
      },

      error(error) {
        console.error("Person failed:", error);
      },

      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    portfolio: {
      query: CompletePortfolio,
      update(data) {
        return data.completePortfolio;
      },
      error(error) {
        console.error("Error fetching portfolio:", error);
      },
      variables() {
        const cvrNumbers = [];
        if (this.person && this.person.relations) {
          this.person.relations
            .filter((relation) => relation.roles.includes("REAL_OWNER"))
            .forEach((relation) => cvrNumbers.push(parseInt(relation.cvrNumber)));
        }
        return {
          id: this.$route.params.id,
          cvrNumbers: cvrNumbers,
        };
      },
    },
  },

  computed: {
    loading() {
      return this.$apollo.queries.person.loading || this.$apollo.queries.portfolio.loading;
    },

    propertiesCount() {
      if (this.portfolio) {
        return this.portfolio.length;
      }

      return 0;
    },

    condosCount() {
      if (this.portfolio) {
        return this.portfolio.filter((p) => getPropertyType(p) == PROPERTY_TYPE_CONDO).length;
      }

      return 0;
    },

    bofgCount() {
      if (this.portfolio) {
        return this.portfolio.filter((p) => getPropertyType(p) == PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT).length;
      }

      return 0;
    },

    unknownCount() {
      if (this.portfolio) {
        return this.portfolio.filter((p) => getPropertyType(p) == PROPERTY_TYPE_UNKNOWN).length;
      }

      return 0;
    },

    companyRelationsCount() {
      if (this.person.relations) {
        return this.person.relations.length;
      }

      return 0;
    },
  },

  methods: {
    navigateTo(path, query) {
      this.$amplitude.track({
        event_type: `nagivate from: overview-dashboard (person), to: ${path}`,
      });

      this.$router.push({
        name: path,
        query: query || {},
        params: {
          id: this.$route.params.id,
        },
      });
    },
  },
};
